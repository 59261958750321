import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import PageBase from '../../layout/PageBase';
import { HouseMess } from '../HouseIcons';
import { Lawn } from '../Lawn';

import styles from '../../../styles/sass/components/NotFound.module.scss';

const ModalPadding = 75 + 64; // HeaderHeight + modal Pagging
const ContentHeightSpan = 44 + 96; // Title + House Icon
const NotFound = ({ insideModal, handleClose }: any) => {
  const [height, setHeight] = useState(500);

  const updateWindowDimensions = () => {
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <PageBase disableOverflow>
      <div
        className={`col-xs-12 middle-xs center-xs ${styles.pageNotFound}`}
        style={
          insideModal ? { paddingTop: 0, height: height - ModalPadding } : {}
        }
      >
        <h1>Something&#39;s not quite right...</h1>
        <h3>
          {insideModal ? (
            <a onClick={handleClose}>Go Back</a>
          ) : (
            <Link href="/">
              <a>Go Home.</a>
            </Link>
          )}
        </h3>
        <div
          className={styles.houseAndLawnContainer}
          style={
            insideModal
              ? { height: height - ModalPadding - ContentHeightSpan }
              : {}
          }
        >
          <div className={styles.lawnContainer}>
            <div className={styles.lawnInner}>
              <Lawn />
            </div>
          </div>
          <div
            className={styles.houseIconContainer}
            style={insideModal ? { top: 'auto', bottom: 0 } : {}}
          >
            <div className={styles.houseIcon}>
              <HouseMess />
            </div>
          </div>
        </div>
      </div>
    </PageBase>
  );
};

export default NotFound;
